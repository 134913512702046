<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GoogleCallback',
  methods: {
    ...mapActions(['socialLogin', 'sendLoginSignal'])
  },
  async mounted () {
    this.$root.$loading.open({ title: 'Cargando información...' })
    try {
      const query = this.$route.query
      if (!query.code) {
        await this.$router.push({ name: 'Login' })
      }
      const provider = this.$route.params.provider
      await this.socialLogin({ provider, payload: { code: query.code } })
      await this.sendLoginSignal()
      await this.$router.push({ name: 'Inicio' })
    } catch (e) {
      this.$root.$loading.close()
      if (e.response?.data?.non_field_errors[0]?.startsWith('User is already registered')) {
        await this.$root.$confirm({
          title: 'Lo sentimos',
          message: 'Ya hay un usuario registrado con ese email.',
          acceptText: 'ok'
        })
      } else {
        await this.$root.$confirm({
          title: 'Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo nuevamente en unos minutos.',
          acceptText: 'ok'
        })
      }
      await this.$router.push({ name: 'Login' })
    } finally {
      this.$root.$loading.close()
    }
  }
}
</script>

<style scoped>

</style>
